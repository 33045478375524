import React from "react";
import flexCardData from "./FlexCardData";
import "./FlexCard.css";

const FlexCard = () => {
  return (
    <div className="flex-container">
      {/* -- ======= Flex Box Section ======= -- */}
      <div className="flexbox">
        <div className="flexbox title" data-aos="fade-down">
          <h2 className="heading">CORE COMPETENCIES</h2>
        </div>
        {flexCardData.map(({ id, colorClass, title, subtitle, text, icon, delay }) => (
          <div
            key={id}
            className={`flexcard ${colorClass}`}
            data-aos="zoom-in"
            data-aos-delay={delay}
          >
            <div className={`flexcardNumber ${colorClass}`}>{title}</div>
            <div className="flex flexcardTitle">{subtitle}</div>
            <div className="flex flexcardText">{text}</div>
            <div className="flex flexcardIcon">{icon}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FlexCard;

