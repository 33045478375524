import React from "react";
import { FaPhone, FaMailBulk, FaClock } from "react-icons/fa";
import Button from "../UI/Button/Button";
import "./Header.css";

const Header = () => {
  return (
    <header className="header-wrapper">
      <div className="header-limiter">
        <ul>
          <li>
            <span className="icon" aria-label="Business hours">
              <FaClock /> Monday - Friday, 8AM to 5PM
            </span>
          </li>
          <li>
            <span
              className="icon"
              onClick={() =>
                (window.location.href = "mailto:service@dexterityliftsei.com")
              }
              aria-label="Email us"
            >
              <FaMailBulk /> service@dexterityliftsei.com
            </span>
          </li>
          <li>
            <span
              className="icon"
              onClick={() => (window.location.href = "tel:+18767788493")}
              aria-label="Call us"
            >
              <FaPhone /> Call us now (876) 778-8493
            </span>
          </li>
          <li>
            <Button
              text={"Make Inquiry"}
              className={"btn btn-dark"}
              href="/contact"
            />
          </li>
        </ul>
      </div>
    </header>
  );
};

export default Header;
