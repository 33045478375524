import React from "react";
import {
  FaFacebook,
  FaFax,
  FaInstagram,
  FaLinkedin,
  FaMailBulk,
  FaPhone,
  FaTwitter,
} from "react-icons/fa";
import { MdLocationOn } from "react-icons/md";
import ToTop from "../totop/ToTop";
import "./Footer.css";

const Footer = () => {
  return (
    <div className="footer">
      <hr className="midline" />
      <div className="footer-container" data-aos="fade-in">
        <div className="left">
          <h4>DEXTERITY LIFTS & ELECTRICAL LTD</h4>

          <div className="company-info">
            <div  >
              <MdLocationOn /> 10 Godfrey Dyer Blvd, Montego Bay, St. James
            </div>
            <div
           
              onClick={() => (window.location.href = "tel:+18767788493")}
            >
              <FaPhone /> (876) 778-8493
            </div>
            <div
             
              onClick={() => (window.location.href = "tel:+18767849831")}
            >
              <FaFax /> (876) 784-9831
            </div>
            <div
              
              onClick={() =>
                (window.location.href = "mailto:service@dexterityliftsei.com")
              }
            >
              <FaMailBulk /> service@dexterityliftsei.com
            </div>
          </div>
          <div className="social">
            <ul>
              <li>
                <FaFacebook className="social-icon" />
              </li>
              <li>
                <FaTwitter className="social-icon" />
              </li>
              <li>
                <FaLinkedin className="social-icon" />
              </li>
              <li>
                <FaInstagram className="social-icon" />
              </li>
            </ul>
          </div>
        </div>
        <div className="right">
          <h4>ABOUT</h4>
          <p>
            At DEXTERITY LIFTS, we are your trusted partner for comprehensive
            electrical and mechanical engineering solutions tailored to the
            elevator and electrical industry. Whether you need installation,
            maintenance, or repair services for commercial or residential lifts,
            our team of skilled engineers is here to ensure your systems operate
            smoothly and efficiently. As a licensed distributor of one of the
            world's leading elevator and escalator manufacturers, we pride
            ourselves on providing top-quality products and services that meet
            the highest industry standards.
          </p>{" "}
          <p>
            Our commitment to excellence means that our lifts can be found in
            diverse locations, enhancing mobility for people everywhere. Choose
            DEXTERITY LIFTS for reliable solutions that wil elevate your
            experience. Let us help you move forward with confidence!
          </p>
        </div>
      </div>
      <div className="all-rights">
        <p className="text">
          © DEXTERITY LIFTS & ELECTRICAL LIMITED {new Date().getFullYear()}. All
          rights reserved
        </p>
      </div>
      <ToTop />
    </div>
  );
};

export default Footer;
