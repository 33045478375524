import React from "react";
import { MdMarkEmailRead } from "react-icons/md";
import { BsFacebook, BsLinkedin, BsPhone } from "react-icons/bs";
import { GrInstagram, GrYoutube } from "react-icons/gr";
import { TbBuildingSkyscraper } from "react-icons/tb";
import { IoLogoTwitter } from "react-icons/io";
import { SiMinutemailer } from "react-icons/si";
import { MuiTelInput } from "mui-tel-input";
import companyLocation from "../../assets/img/company/location.png";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import validate from "./FormValidation";
import axios from "axios";
import UseForm from "./UseForm";
import "./Form.css";
const FormContact = () => {

  const submitForm = async (event) => {
    try {
      const res = await axios.post('/api-email', {
        name: values.name,
        email: values.email,
        phone: values.phone,
        message: values.message,
      });
      // Return to page to after submission
      window.scrollTo(0, 0);
      toast.success("Message sent.", {
        position: "top-right",
        autoClose: 3000,
        closeOnClick: true,
      });
    } catch (error) {
      // Return to page to after submission
      window.scrollTo(0, 0);
      const errorMessage = error.response ? error.response.data.message || "Email failed! Try from your inbox instead." : "Network error! Please try again later.";
      console.error("Error sending email:", errorMessage);
      toast.error(errorMessage, {
        position: "top-right",
        autoClose: 1500,
        closeOnClick: true,
      });
    }
  }; // End submitForm // End submitForm

  // Custom hook call here
  const {
    handleSubmit,
    handleChange,
    handleBlur,
    values,
    errors,
    isSubmitting,
  } = UseForm(validate, submitForm);

  return (
    <div className="contact-container" data-aos="zoom-in">
      <div className="toaster">
        {/* Toastify components */}
        <ToastContainer />
      </div>
      <div className="form-header">
        <h1 className="u-title h2">CONTACT US</h1>
        <h3>CONTACT US TODAY FOR YOUR FREE ESTIMATE.</h3>
        <p>
          Ready to start your next project with us? Give us a call or send us an
          message!
        </p>
      </div>

      {/*-- FORM CONTAINER --*/}
      <div className="form-container">
        <div className="left-container">
          <div className="left-inner-container">
            <h3 className="lg-view">COMPANY INFO</h3>
            <p className="lg-view">
              Feel free to send us a message in the contact form.
            </p>

            {/*---- COMPANY INFORMATION ----*/}
            <div className="company-info">
              <ul className="icons-container">
                <li>
                  <BsPhone className="contact-icon"  onClick={() => window.location.href = 'tel:+18767788493'} />
                  <small>Phone: (876) 778-8493, (876) 784-9831 </small>
                </li>
                <li>
                  <MdMarkEmailRead className="contact-icon"  onClick={() => window.location.href = 'mailto:service@dexterityliftsei.com'}  />
                  <small>Email: service@dexterityliftsei.com</small>
                </li>
                <li>
                  <TbBuildingSkyscraper className="contact-icon" />
                  <small>
                    Office Hrs Mon-Fri: 8:00am - 5:00pm, Sat-Sun: Closed
                  </small>
                </li>
              </ul>
              <div className="contact-photo">
                <img src={companyLocation} alt="location" />
              </div>
            </div>
            {/*---- End .company-info ----*/}
          </div>
        </div>

        {/*---- FORM CONTAINER ----*/}
        <div className="right-container">
          <div className="right-inner-container">
            {/*---- CONTACT FORM STARTS HERE ----*/}
            <form onSubmit={handleSubmit} className="form" autoComplete="on">
              <h3 className="u-text-dark">SEND US YOUR ENQUIRY</h3>

              {/*---- SOCIAL MEDIA ICON STARTS HERE ----*/}
              <ul className="social-media">
                <li>
                  <a href={"/#faq"} className="instagram">
                    <GrInstagram />
                  </a>
                </li>
                <li>
                  <a href={"/#faq"} className="twitter">
                    <IoLogoTwitter />
                  </a>
                </li>
                <li>
                  <a href={"/#faq"} className="facebook">
                    <BsFacebook />
                  </a>
                </li>
                <li>
                  <a href={"/#faq"} className="linkedin">
                    <BsLinkedin />
                  </a>
                </li>
                <li>
                  <a href={"/#faq"} className="youtube">
                    <GrYoutube />
                  </a>
                </li>
              </ul>

              <div className="form-inputs">
                <label className="form-label" htmlFor="name">
                  Full Name
                </label>
                <input
                  className="form-input"
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter your full name..."
                  onBlur={handleBlur}
                  value={values.name}
                  onChange={handleChange}
                />
                {errors.name && <p>{errors.name}</p>}
              </div>
              <div className="form-inputs">
                <label className="form-label" htmlFor="email">
                  Email
                </label>
                <input
                  className="form-input"
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Enter your email..."
                  onBlur={handleBlur}
                  value={values.email}
                  onChange={handleChange}
                  autoComplete="on"
                />
                {errors.email && <p>{errors.email}</p>}
              </div>
              {/*---- MuiTelInput Start Here ----*/}
              <div className="form-inputs">
                <label className="form-label" htmlFor="phone">
                  Phone Number
                </label>
                <MuiTelInput
                  id="phone"
                  name="phone"
                  fullWidth
                  defaultCountry="JM"
                  variant="outlined"
                  onBlur={handleBlur}
                  value={values.phone}
                  onChange={handleChange}
                />
                {errors.phone && <p>{errors.phone}</p>}
              </div>
              <div className="form-inputs">
                <label className="form-label" htmlFor="message">
                  Message
                </label>
                <textarea
                  className="form-input"
                  rows="6"
                  type="text"
                  id="message"
                  name="message"
                  placeholder="Message..."
                  onBlur={handleBlur}
                  value={values.message}
                  onChange={handleChange}
                />
                {errors.message && <p>{errors.message}</p>}
              </div>
              <button
                className="form-input-btn"
                type="submit"
                disabled={isSubmitting && true}
              >
                {isSubmitting ? "Loading.." : "Send"} <SiMinutemailer />
              </button>
            </form>
            {/*-- End .contact form end here --*/}
          </div>
        </div>
        {/*-- End .form container --*/}
      </div>
    </div>
  );
};

export default FormContact;
