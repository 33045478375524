import React  from "react";
import "./Process.css";
import { BsFillBookmarkStarFill } from "react-icons/bs";
import { ProcessData } from "./ProcessData";
import StatsCount from "../statscounter/StatsCount";
import { CountData } from "../statscounter/data";

const Process = () => {

  const initial =  {
    y: 40,
    opacity: 0,
  };
  const animate = {
    y: 0,
    opacity: 1,
  };

  return (
    <div className="process">
      <div className="u-title">
        <BsFillBookmarkStarFill color="orangered" size={40} />
        <h2 className="u-title h2">What We Offer</h2>
      </div>

      <div className="counter-wrapper" data-aos="zoom-in" data-aos-delay="100">
        {CountData.map((count) => (
          <StatsCount key={count.id} data={count} />
        ))}
      </div>

      <div className="process-wrapper" data-aos="fade-up" data-aos-delay="200">
        {ProcessData.map((k, index) => (
          <div
            className="process-column"
            
            initial={initial}
            animate={animate}
            transition={{ duration: 0.5 + index * 0.1 }}
            key={index}
            data-aos="zoom-in" 
            data-aos-delay="100"
          >
            <div className={k.id}>{k.icon}</div>
            <div className="process-name">{k.name}</div>
            <div className="process-text">{k.description}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Process;
