import React from "react";
import Button from "../UI/Button/Button";
import "./Error.css";

const Error = () => {
  return (
    <div className="error">
      <h1 className="code">404</h1>
      <div className="page">
        <h3>
          Oops! The page you are looking for is not found. Go back to the
          home page instead.
        </h3>
        <p>
          Sorry about that. We've logged the error for review, in case it's our
          fault.
        </p>

        <div className="button">
        <Button
          text={"Back to home"}
          className={"btn btn-dark"}
          href={"/home"}
        />
      </div>
      </div>
      <div className="jc-elevator">
        <div id="myBtn" className="jc-floor">
          <h3>404</h3>
        </div>
        <div id="doors" className="jc-doors">
          <div>Ops... Wrong floor</div>
          <div>Return using button<a className="class-link" href={"/home"}>Home</a></div>
        </div>
        <div className="jc-switch">
          <a href={"/home"}>^</a>
          <a href={"/home"}>^</a>
        </div>
      </div>
    </div>
  );
};

export default Error;
